import React from "react"

import "./styles/footer.scss"
import { StaticImage } from "gatsby-plugin-image"

const badgeSize = 240;

const Footer = ({}) => {

  return <>
    <footer>
      <div className="container">
        <div className="elteto-badges">
          <h3>Balatonfelvidék ajánló</h3>
          <a href="https://eltetobalatonfelvidek.hu/" target="_blank" rel="noreferrer">
              <StaticImage src={"../images/elteto.jpg"} alt={"Balatonfelvidék program"} width={badgeSize} />
          </a>
          <a href="https://eltetovedjegy.hu/termekkatalogus/" target="_blank" rel="noreferrer">
              <StaticImage src={"../images/elteto-katalogus.jpg"} alt={"Balatonfelvidéki termékkatalógus"} width={badgeSize} />
          </a>
          <a href="https://balatonfelvidekitura.hu/wp-content/themes/leadertura/ajanlo/turisztikai_ajanlo.pdf"
             target="_blank" rel="noreferrer">
              <StaticImage src={"../images/elteto-turisztika.jpg"} alt={"Balatonfelvidéki turisztikai ajánló"} width={badgeSize} />
          </a>


            <a href="https://eltetovedjegy.hu/uzletek/"
                target="_blank" rel="noreferrer">
                <StaticImage src={"../images/elteto-uzlet-2.jpg"} alt={"Balatonfelvidéki üzletek"} width={badgeSize} />
            </a>


            <a href="https://eltetovedjegy.hu/termekkatalogus/"
                target="_blank" rel="noreferrer">
                <StaticImage src={"../images/elteto-katalogus-2.jpg"} alt={"Balatonfelvidéki termékkatalógus"} width={badgeSize} />
            </a>


            <a href="https://balatonfelvidekitura.hu/turisztikai_ajanlo/"
                target="_blank" rel="noreferrer">
                <StaticImage src={"../images/elteto-turisztika-2.jpg"} alt={"Balatonfelvidéki turisztikai ajánló"} width={badgeSize} />
            </a>

        </div>
        <div className="content">
          <h3>
            Sövényházi Balázs őstermelő-kistermelő
          </h3>
          <p>
            8248 Nemesvámos, Kossuth Lajos utca 352.
          </p>
          <h3>
            Vámosi Betyárok
          </h3>
          <p>
            8248 Nemesvámos Pap István utca 50.
          </p>
          <p>
            <a href="https://www.facebook.com/balazs.sovenyhazi" target="_blank" rel="noreferrer">
              Facebook
            </a>
          </p>
        </div>
      </div>
    </footer>
  </>
}
export default Footer
