import * as React from "react"
import { Link } from "gatsby"

import Left from '../images/svg/motive-left.svg';
import Right from '../images/svg/motive-right.svg';
import Logo from '../images/svg/logo.svg';

import "./styles/header.scss"

const Header = ({ siteTitle }) => (
  <header className="container">
    <p className="logo">
      <Left height="2.5rem"/>
      <Logo height="8rem"/>
      <Right height="2.5rem"/>

    </p>
    <nav>
      <ul>
        <li><Link to="/" activeClassName="active">Gazdaság</Link></li>
        <li><Link to="/kapcsolat/" activeClassName="active">Kapcsolat</Link></li>
        <li><Link to="/rendezvenyek/" activeClassName="active">Rendezvények</Link></li>
        <li><Link to="/partnerek/" activeClassName="active">Partnerek</Link></li>
      </ul>
    </nav>
  </header>
)

export default Header
